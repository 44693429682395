// Here you can add other styles
body {
  font-family: 'Roboto', sans-serif;
  background-color: $white;
  font-style: normal;
  font-weight: 500;

  .sub-header {
    background-color: $white;
    border-bottom: none;
  }
  .header-title {
    font-weight: 600;
    color: $black;
  }
  .btn-link-wallet {
    background-color: rgb(239, 244, 245);
    color: $primary;
  }
  .btn-radius-50 {
    border-radius: 50px;
  }
  .btn-font-style {
    font-size: 0.9375rem;
    line-height: 18px;
  }
  .content-title {
    font-size: 1rem;
    line-height: 19px;
  }
  .detail-title-font {
    font-size: 0.75rem;
    line-height: 14px;
    color: #828282;
  }
  .border-radius-modal {
    .modal-content {
      border-radius: 20px;
    }
  }
  .modal-title-style {
    font-size: 1.125rem;
    line-height: 21px;
    color: #000000;
  }
  .table td {
    padding: 0.2rem 0 0.2rem 0.2rem;
  }
  .table th {
    padding: 0.5rem 0 0.5rem 0.2rem;
  }
  .listing-img-card {
    position: relative;
    border-radius: 20px 20px 0 0;
    border: none;
    // max-height: 400px;
    // width: 100%;
  }

  .listing-img-card svg {
    width: 100%;
  }

  .listing-img-card img {
    border-radius: 20px 20px 0 0;
  }
  .listing-card-body {
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    height: 70%;
    width: 100%;
    .card-title {
      position: absolute;
      bottom: 0;
    }
  }

  .dr-menu-filter {
    border-radius: 0 0 20px 20px;
    transform: translate3d(0px, 56px, 0px) !important;
  }
  .form-check-input {
    width: 20px;
    height: 20px;
    position: relative;
  }

  .activity-log-table-nav {
    .nav-link {
      color: #828282;
      &.active {
        border-bottom: 1px solid $primary;
        color: $primary;
      }
    }
  }
  .search-container {
    label {
      margin-bottom: 0.2rem;
      font-weight: bold;
    }
    .textCol {
      margin-bottom: 1rem;
      font-size: 1rem;
      line-height: 19px;
    }

    .btn-radius-50i {
      border-radius: 50px !important;
    }

    .btn-radius-25i {
      border-radius: 25px !important;
    }

    .collapse-icon {
      color: rgba(0, 0, 0, 0.5);
      box-shadow: none !important;
      &:hover {
        color: black;
        box-shadow: none !important;
      }
    }

    .toggle-collapse-btn {
      transform: rotate(90deg);
    }

    .btn-tool {
      background-color: transparent;
      color: #adb5bd;
      font-size: 0.875rem;
      margin: -0.75rem 0;
      padding: 0.25rem 0.5rem;
    }

    .pr-5px {
      padding-right: 5px;
    }

    .pl-5px {
      padding-left: 5px;
    }

    .px-5px {
      padding-left: 5px;
      padding-right: 5px;
    }

    .dateInput {
      padding-right: 6px;
    }

    .form-control-sm {
      height: calc(1.8125rem + 2px);
      padding: 0.25rem 0.5rem;
      font-size: 0.85rem;
      line-height: 1.5;
      border-radius: 0.2rem;
    }

    ::-webkit-calendar-picker-indicator {
      margin-left: 0px;
      padding-right: 0px;
    }

    .card-header {
      background-color: initial;
    }
  }

  .c-header-nav {
    .nav-item-filter {
      position: initial;
      .dr-item-filter {
        position: initial;
      }
    }
  }

  .btn-copy {
    vertical-align: initial !important;
    .c-icon {
      vertical-align: top;
    }
  }

  .c-sidebar-footer {
    border-top: 1px solid $light;
  }
  .font-size-075 {
    font-size: 0.75rem;
  }

  //backgound-gradient
  .c-sidebar.c-sidebar-light .c-sidebar-brand {
    /* ff 3.6+ */
    background: -moz-linear-gradient(90deg, rgba(5, 195, 255, 1) 0%, rgba(134, 89, 254, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-linear-gradient(90deg, rgba(5, 195, 255, 1) 0%, rgba(134, 89, 254, 1) 100%);

    /* opera 11.10+ */
    background: -o-linear-gradient(90deg, rgba(5, 195, 255, 1) 0%, rgba(134, 89, 254, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8659FE', endColorstr='#05C3FF', GradientType=0 );

    /* ie 10+ */
    background: -ms-linear-gradient(90deg, rgba(5, 195, 255, 1) 0%, rgba(134, 89, 254, 1) 100%);

    /* global 94%+ browsers support */
    background: linear-gradient(90deg, rgba(5, 195, 255, 1) 0%, rgba(134, 89, 254, 1) 100%);
  }
}

//============Loading Transaction=========//
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 15px;
}
.lds-ellipsis div {
  position: absolute;
  top: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $primary;
  opacity: 0.8;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.card-filter {
  background-color: #f4fbff;
  color: #1cb0ff;
}

.text-anft-gradiant {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
  background: linear-gradient(90deg, #6971ff 0%, #1cb0ff 100%);
  --cui-btn-hover-bg: linear-gradient(90deg, #6971ff 0%, #1cb0ff 100%);
  --cui-btn-active-bg: linear-gradient(90deg, #6971ff 0%, #1cb0ff 100%);
}

.text-gradient {
  /* ff 3.6+ */
  background: -moz-linear-gradient(180deg, rgba(105, 113, 255, 1) 0%, rgba(28, 176, 255, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(180deg, rgba(105, 113, 255, 1) 0%, rgba(28, 176, 255, 1) 100%);

  /* opera 11.10+ */
  background: -o-linear-gradient(180deg, rgba(105, 113, 255, 1) 0%, rgba(28, 176, 255, 1) 100%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1CB0FF', endColorstr='#6971FF', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(180deg, rgba(105, 113, 255, 1) 0%, rgba(28, 176, 255, 1) 100%);

  /* global 94%+ browsers support */
  background: linear-gradient(180deg, rgba(105, 113, 255, 1) 0%, rgba(28, 176, 255, 1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.filter-search-select {
  color: #1cb0ff;
  border: 1px solid #1cb0ff;
  border-radius: 6px;
  background-color: var(--cui-form-select-bg, rgba(255, 255, 255, 1));

  // use a static image instead
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"%3E%3Cpath fill="white" d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-size: 16px 12px;

  option {
    background-color: rgba(28, 176, 255, 0.25);
    color: black;
  }
}

.toggle-collapse-btn {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

//Style react-select input
.css-1s2u09g-control, .css-1pahdxg-control {
  border: none !important;
  border-radius: 50px !important;
}